var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('XyzTransition',{attrs:{"xyz":"fade"}},[(_vm.$store.state.shotShow)?_c('span',{staticClass:"title"},[_vm._v("拍照模式")]):_vm._e()]),_c('XyzTransition',{attrs:{"xyz":"fade"}},[(_vm.$store.state.shotShow)?_c('img',{staticClass:"shadow",attrs:{"src":_vm.$store.state.chat.yaoganWatch === 2
          ? require('@assets/img/shadow.png')
          : require('@assets/img/shadowr.png'),"alt":""}}):_vm._e()]),(_vm.light)?_c('div',{staticClass:"light ",class:{
      'light-hover': _vm.light
    }}):_vm._e(),_c('div',{staticClass:"code",staticStyle:{"display":"none"},attrs:{"id":"code"}}),_c('XyzTransition',{attrs:{"xyz":"fade"}},[(_vm.$store.state.shotShow)?_c('div',{staticClass:"close",style:({
        background: ("url('" + (require('@assets/img/close3.png')) + "') no-repeat")
      }),on:{"click":_vm.close}}):_vm._e()]),_c('XyzTransition',{attrs:{"xyz":"fade"}},[(_vm.$store.state.shotShow)?_c('div',{staticClass:"shot",style:({
        background: ("url('" + (require('@assets/img/pai.png')) + "') no-repeat")
      }),on:{"click":_vm.shot}}):_vm._e()]),_c('XyzTransition',{attrs:{"xyz":"fade"}},[(_vm.base64)?_c('div',{staticClass:"ceng",class:{
        rotate: _vm.rotate
      },style:({
        left: _vm.phone.left,
        top: _vm.phone.top,
        width: _vm.phone.width,
        height: _vm.phone.height
      })},[_c('img',{attrs:{"src":_vm.base64}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.weixin),expression:"weixin"}],staticClass:"weixinclose",style:({
          background: ("url('" + (require('@assets/img/close4.png')) + "') no-repeat")
        }),on:{"click":_vm.complete}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.weixin),expression:"weixin"}],staticClass:"weixintip"},[_vm._v("长按保存至相册")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }