<template>
  <div>
    <XyzTransition xyz="fade">
      <span class="title" v-if="$store.state.shotShow">拍照模式</span>
    </XyzTransition>

    <XyzTransition xyz="fade">
      <img
        class="shadow"
        v-if="$store.state.shotShow"
        :src="
          $store.state.chat.yaoganWatch === 2
            ? require('@assets/img/shadow.png')
            : require('@assets/img/shadowr.png')
        "
        alt=""
      />
    </XyzTransition>

    <div
      v-if="light"
      class="light "
      :class="{
        'light-hover': light
      }"
    ></div>

    <div style="display:none" class="code" id="code"></div>

    <XyzTransition xyz="fade">
      <div
        v-if="$store.state.shotShow"
        class="close"
        @click="close"
        :style="{
          background: `url('${require('@assets/img/close3.png')}') no-repeat`
        }"
      ></div>
    </XyzTransition>

    <XyzTransition xyz="fade">
      <div
        v-if="$store.state.shotShow"
        class="shot"
        @click="shot"
        :style="{
          background: `url('${require('@assets/img/pai.png')}') no-repeat`
        }"
      ></div>
    </XyzTransition>

    <XyzTransition xyz="fade">
      <div
        class="ceng"
        :class="{
          rotate: rotate
        }"
        v-if="base64"
        :style="{
          left: phone.left,
          top: phone.top,
          width: phone.width,
          height: phone.height
        }"
      >
        <img :src="base64" />
        <div
          v-show="weixin"
          @click="complete"
          class="weixinclose"
          :style="{
            background: `url('${require('@assets/img/close4.png')}') no-repeat`
          }"
        ></div>
        <span v-show="weixin" class="weixintip">长按保存至相册</span>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'

function getParmas(url) {
  if (url.includes('?')) {
    var temp1 = url.split('?')
    var pram = temp1[1]
    var keyValue = pram.split('&')
    var obj = {}
    for (var i = 0; i < keyValue.length; i++) {
      var item = keyValue[i].split('=')
      var key = item[0]
      var value = item[1]
      obj[key] = value
    }
    return obj
  } else return null
}

export default {
  data() {
    return {
      light: false, // 闪光
      base64: '', // 图片
      phone: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      weixin: false,
      rotate: false,
      url: null
    }
  },
  methods: {
    shot() {
      try {
        let bgm = document.getElementById('touchbgm')
        if (bgm) {
          bgm.play()
        }

        if (this.url) {
          this.url.clear()
          this.url.makeCode(location.href + '?sceneId=' + this.$store.state.sceneCurrentIndex)
        } else {
          this.url = new QRCode('code', {
            text: location.href + '?sceneId=' + this.$store.state.sceneCurrentIndex,
            width: 120,
            height: 120,
            colorDark: '#333333', //二维码颜色
            colorLight: '#ffffff', //二维码背景色
            correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
          })
        }

        this.phone = {
          width:
            window.screen.width * (this.$store.state.chat.yaoganWatch === 2 ? 0.65 : 0.58) + 'px',
          height:
            window.screen.height * (this.$store.state.chat.yaoganWatch === 2 ? 0.65 : 0.58) + 'px',
          left: `calc(50% - ${(window.screen.width *
            (this.$store.state.chat.yaoganWatch === 2 ? 0.65 : 0.58)) /
            2}px)`,
          top: `calc(${this.$store.state.chat.yaoganWatch === 2 ? '50%' : '46%'} - ${(window.screen
            .height *
            (this.$store.state.chat.yaoganWatch === 2 ? 0.65 : 0.58)) /
            2}px)`
        }

        this.base64 = ''
        this.rotate = false
        this.light = true

        setTimeout(() => {
          this.light = false
          this.zipai(
            document
              .getElementById('code')
              .querySelector('canvas')
              .toDataURL('image/png')
          )
        }, 300)
      } catch (error) {
        console.log(error)
      }
    },
    async zipai(src) {
      const base64 = await this.compress(
        document.o2.shot(),
        this.$store.state.chat.yaoganWatch === 2 ? 1920 : 1080,
        this.$store.state.chat.yaoganWatch === 2 ? 1080 : 1920,
        src
      )
      this.base64 = base64
      this.$message.success('自拍成功')

      if (!this.weixin) {
        setTimeout(() => {
          this.base64 = ''
        }, 2000)
      }
    },
    compress(base64String, w, quality, src) {
      let a = document.createElement('a')

      a.style.display = 'none'
      var that = this
      var getMimeType = function(urlData) {
        var arr = urlData.split(',')
        var mime = arr[0].match(/:(.*?);/)[1]
        return mime
      }
      var newImage = new Image()
      var imgWidth, imgHeight

      newImage.src = base64String

      return new Promise((resolve) => {
        newImage.onload = function() {
          imgWidth = newImage.width
          imgHeight = newImage.height
          var canvas = document.createElement('canvas')
          var ctx = canvas.getContext('2d')
          if (Math.max(imgWidth, imgHeight) > w) {
            if (imgWidth > imgHeight) {
              canvas.width = w
              canvas.height = (w * imgHeight) / imgWidth
            } else {
              canvas.height = w
              canvas.width = (w * imgWidth) / imgHeight
            }
          } else {
            canvas.width = imgWidth
            canvas.height = imgHeight
          }
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height)

          let imgobj = new Image()
          imgobj.src = src

          imgobj.onload = function() {
            if (that.$store.state.chat.yaoganWatch === 2) {
              ctx.drawImage(imgobj, canvas.width - 200, canvas.height - 200, 150, 150)
              ctx.font = 'bold 20px serif'
              ctx.fillStyle = 'rgba(255,255,255)'
              ctx.fillText('图壤·阅读元宇宙', canvas.width - 200, canvas.height - 20)
            } else {
              ctx.drawImage(imgobj, canvas.width - 120, canvas.height - 140, 100, 100)
              ctx.font = 'bold 12px serif'
              ctx.fillStyle = 'rgba(255,255,255)'
              ctx.fillText('图壤·阅读元宇宙', canvas.width - 115, canvas.height - 20)
            }

            var base64 = canvas.toDataURL(getMimeType(base64String), quality)
            var blob = that.dataURLToBlob(base64)
            a.setAttribute('href', URL.createObjectURL(blob))
            a.setAttribute('download', '自拍.png')
            document.body.appendChild(a)

            if (!that.weixin) {
              a.click()
            }

            URL.revokeObjectURL(blob)
            document.body.removeChild(a)
            resolve(base64)
          }
        }
      })
    },
    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    close() {
      this.$store.commit('changeShotShow', false)
      this.base64 = ''
      this.rotate = false
    },
    complete() {
      this.base64 = ''
      let bgm = document.getElementById('touchbgm')
      if (bgm) {
        bgm.play()
      }
    }
  },
  created() {
    if (navigator.userAgent.indexOf('MicroMessenger') > -1) this.weixin = true

    window.addEventListener(
      'orientationchange',
      () => {
        if (this.base64) {
          this.rotate = !this.rotate
        }
      },
      false
    )
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: fixed;
  left: 42px;
  top: 21px;
  z-index: 13;
  color: #fff;
  font-size: 16px;
  text-shadow: 2px 2px 4px rgba($color: #000000, $alpha: 0.25);
}

.shadow {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
}

.light {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.light-hover {
  opacity: 1;
}

.ceng {
  position: fixed;
  z-index: 13;
  border: 2px solid #fff;

  img {
    width: 100%;
    height: 100%;
    pointer-events: auto !important;
  }

  .weixinclose {
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 36px;
    background-size: $bs !important;
  }

  .weixintip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    color: #fff;
    font-size: 13px;
    text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.3);
  }
}

.rotate {
  transform: rotate(90deg);
}

.close {
  position: fixed;
  right: 59px;
  top: 30px;
  z-index: 13;
  width: 29px;
  height: 29px;
  background-size: $bs !important;
}

.shot {
  position: fixed;
  right: 37px;
  top: calc(50% - 40px);
  z-index: 13;
  width: calc(160px / 2);
  height: calc(160px / 2);
  background-size: $bs !important;
}

.code {
  width: 50px;
  height: 50px;
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .title {
    left: 30px;
    top: 50px;
  }

  .ceng {
    position: fixed;
    z-index: 13;
    border: 2px solid #fff;

    img {
      width: 100%;
      height: 100%;
      pointer-events: auto !important;
    }

    .weixinclose {
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      height: 36px;
      background-size: $bs !important;
    }

    .weixintip {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      color: #fff;
      font-size: 13px;
      text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.3);
    }
  }

  .close {
    right: 28px;
    top: 50px;
  }

  .shot {
    top: initial;
    right: calc(50% - 160px / 4);
    bottom: 45px;
  }
}
</style>
