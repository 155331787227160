var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.mapShow2),expression:"$store.state.mapShow2"}],staticClass:"shadow",on:{"click":_vm.close}},[_c('XyzTransition',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.mapShow),expression:"$store.state.mapShow"}],attrs:{"xyz":"fade small"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v("选择岛屿")]),_c('div',{staticClass:"scene-protect"},_vm._l((_vm.$store.state.initConfig.sceneList),function(item,i){return _c('div',{key:i,staticClass:"scene",class:{
              check: _vm.currentIndex === i && item.open,
              noopen: _vm.currentIndex === i && !item.open
            },on:{"click":function($event){return _vm.changeMap(i)}}},[_c('img',{staticClass:"sceneImg",attrs:{"src":item.src,"alt":""}}),_c('span',[_vm._v(_vm._s(item.sceneName))]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(i === _vm.$store.state.sceneCurrentIndex),expression:"i === $store.state.sceneCurrentIndex"}],staticClass:"current",attrs:{"src":require("@assets/img/current.png"),"alt":""}})])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$store.state.initConfig.sceneList[
              _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex
            ].open
          ),expression:"\n            $store.state.initConfig.sceneList[\n              currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex\n            ].open\n          "}],staticClass:"bigImg"},[_c('img',{attrs:{"src":_vm.$store.state.initConfig.sceneList[
                _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex
              ].src,"alt":""}}),_c('button',{staticClass:"deliver",on:{"click":function($event){return _vm.deliver(
                _vm.$store.state.initConfig.sceneList[
                  _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex
                ],
                _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex
              )}}},[_vm._v(" 开始传送 ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.$store.state.initConfig.sceneList[
              _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex
            ].open
          ),expression:"\n            !$store.state.initConfig.sceneList[\n              currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex\n            ].open\n          "}],staticClass:"bigImg"},[_c('span',{staticClass:"building"},[_vm._v("岛屿建设中")]),_c('div',{staticClass:"buildname",style:({
              background: ("url(" + (require('@assets/img/buildtitle.png')) + ") no-repeat")
            })},[_vm._v(" "+_vm._s(_vm.$store.state.initConfig.sceneList[ _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex ].sceneName)+" ")]),_c('div',{staticClass:"buildintro"},[_vm._v(" "+_vm._s(_vm.$store.state.initConfig.sceneList[ _vm.currentIndex !== -1 ? _vm.currentIndex : _vm.$store.state.sceneCurrentIndex ].intro)+" ")])]),_c('div',{staticClass:"close",style:({
            background: ("url('" + (require('@assets/img/close2.png')) + "') no-repeat")
          }),on:{"click":_vm.close}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }