<template>
  <div>
    <div class="shadow" v-show="$store.state.mapShow2" @click="close">
      <XyzTransition xyz="fade small" v-show="$store.state.mapShow" @click.stop="">
        <div class="box">
          <div class="title">选择岛屿</div>

          <div class="scene-protect">
            <div
              class="scene"
              :class="{
                check: currentIndex === i && item.open,
                noopen: currentIndex === i && !item.open
              }"
              v-for="(item, i) in $store.state.initConfig.sceneList"
              :key="i"
              @click="changeMap(i)"
            >
              <img class="sceneImg" :src="item.src" alt="" />
              <span>{{ item.sceneName }}</span>
              <img
                class="current"
                src="@assets/img/current.png"
                alt=""
                v-show="i === $store.state.sceneCurrentIndex"
              />
            </div>
          </div>

          <div
            class="bigImg"
            v-show="
              $store.state.initConfig.sceneList[
                currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
              ].open
            "
          >
            <img
              :src="
                $store.state.initConfig.sceneList[
                  currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
                ].src
              "
              alt=""
            />

            <button
              class="deliver"
              @click="
                deliver(
                  $store.state.initConfig.sceneList[
                    currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
                  ],
                  currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
                )
              "
            >
              开始传送
            </button>
          </div>

          <div
            class="bigImg"
            v-show="
              !$store.state.initConfig.sceneList[
                currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
              ].open
            "
          >
            <span class="building">岛屿建设中</span>

            <div
              class="buildname"
              :style="{
                background: `url(${require('@assets/img/buildtitle.png')}) no-repeat`
              }"
            >
              {{
                $store.state.initConfig.sceneList[
                  currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
                ].sceneName
              }}
            </div>
            <div class="buildintro">
              {{
                $store.state.initConfig.sceneList[
                  currentIndex !== -1 ? currentIndex : $store.state.sceneCurrentIndex
                ].intro
              }}
            </div>
          </div>

          <div
            class="close"
            :style="{
              background: `url('${require('@assets/img/close2.png')}') no-repeat`
            }"
            @click="close"
          ></div></div
      ></XyzTransition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: -1,
      deliverHover: false
    }
  },
  methods: {
    async deliver(e, i) {
      console.log(e, i)

      try {
        this.change = false

        if (this.currentIndex === -1) {
          this.$message.info('请先选择')
          return
        }

        if (this.currentIndex === this.$store.state.sceneCurrentIndex) {
          this.$message.info('您已经在该场景了')
          return
        }

        if (e.open) {
          try {
            // 如果点的是现有的
            if (
              this.$store.state.initConfig.sceneList[this.$store.state.sceneCurrentIndex]
                .sceneId === e.sceneId
            ) {
              this.change = true
            }

            let instance = this.$loading({
              fullscreen: true,
              attach: 'body',
              preventScrollThrough: false,
              showOverlay: true,
              text: '请求中..'
            })

            // 30秒自动关闭
            const timer = setTimeout(() => {
              clearTimeout(timer)
              instance.hide()
              instance = null
              console.log('超时,自动关闭窗口')
            }, 30000)

            // 判断是否要切换场景
            let isChangeScene = true

            if (this.change) {
              isChangeScene = false
            }

            if (isChangeScene && this.$store.state.roomId !== e.roomId) {
              const config = [
                this.$store.state.websocket,
                {
                  name: this.$store.state.chat.role.name,
                  id: this.$store.state.chat.role.id,
                  auth: this.$store.state.chat.role.auth,
                  info: {
                    modelIdx: this.$store.state.chat.role.black
                  }
                },
                e.roomId
              ]

              if (!document.o2.role) {
                document.o2.multiplayer.init(...config) // 连接websocket 并创建人物
              } else {
                this.$store.commit('chat/clearPlayer')
                document.o2.multiplayer.reLogin(e.roomId, {
                  name: this.$store.state.chat.role.name,
                  id: this.$store.state.chat.role.id,
                  auth: this.$store.state.chat.role.auth,
                  info: {
                    modelIdx: this.$store.state.chat.role.black
                  }
                })
              }
            }

            if (instance) {
              instance.hide()
              clearTimeout(timer)
            }

            this.$store.commit('changeScene', i)
            this.close() // 传送界面
            this.$store.commit('chat/changeLoading', isChangeScene)

            await this.$o2Init.loadScene(false, isChangeScene)

            this.$store.commit('setroomId', e.roomId)
          } catch (error) {
            throw error
          }
        } else {
          this.$message.info('暂未开放')
        }
      } catch (error) {
        this.$message.error('切换场景失败: ' + error)
      }
    },
    changeMap(i) {
      let bgm = document.getElementById('touchbgm')
      if (bgm) {
        bgm.currentTime = 0
        bgm.play()
      }
      this.currentIndex = i
    },
    close() {
      this.$store.commit('changeMapShow', false)
      setTimeout(() => {
        this.$store.commit('changeMapShow2', false)
      }, 500)
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
// 移动端 横屏
@media screen and (max-width: 999px) and (max-height: 500px) {
  .shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 99;

    .box {
      position: relative;
      width: calc(100% - 79px * 2);
      height: calc(100% - 27px * 2);
      border-radius: 12px;
      background: #0c2033;
      border: 1px solid rgba(57, 198, 176, 0.7);
      overflow: hidden;

      .title {
        font-size: 18px;
        color: #fff;
        margin: 13px 0 5px 17px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .scene-protect {
        display: flex;
        flex-wrap: wrap;
        width: calc(268px - 6px);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100% - 50px - 10px);
        padding-left: 10px;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
          width: 2px;
          height: 10px;
        }
        /* 设置滚动条的轨道 */
        &::-webkit-scrollbar-track {
          background: #0c2033;
        }
        /* 设置滚动条里面的小方块 */
        &::-webkit-scrollbar-thumb {
          background: rgba($color: #b3b9bc, $alpha: 0.3);
          border-radius: 10px;
        }

        .scene {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: calc(114px);
          height: calc(58px);
          border-radius: 8px;
          margin: 6px 0 0 6px;
          background: rgba($color: #fff, $alpha: 0.1);
          border: 1px solid rgba(255, 255, 255, 0.15);

          .sceneImg {
            width: calc(46px * 0.8);
          }

          span {
            color: #ebebeb;
            font-size: 14px;
            transform: scale(0.8) translateY(-4px);
          }

          .current {
            position: absolute;
            right: 3px;
            bottom: 3px;
            width: 20px;
          }
        }

        .check {
          border: 1px solid #fff;
          background: linear-gradient(to left, #236fc6, #39c6b0);
        }

        .noopen {
          border: 1px solid #fff;
        }
      }

      .bigImg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 272px);
        height: calc(100%);
        background: linear-gradient(to bottom, #0e253f, #0e3f3f);

        .building {
          color: #9c9c9c;
          font-size: 16px;
        }

        .buildname {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(173px);
          height: calc(25px);
          background-size: $bs !important;
          color: #aecad5;
          font-size: 12px;
          margin: 20px 0 0 0;
        }

        .buildintro {
          color: #aecad5;
          margin: 6px 32px 0 32px;
        }

        img {
          width: calc(664px * 0.3);
          height: calc(664px * 0.3);
        }

        .deliver {
          width: calc(235px);
          height: calc(40px);
          border-radius: 8px;
          border: 0;
          outline: 0;
          color: #fff;
          background: linear-gradient(to right, #39c6b0 0%, #236fc6 50%, #39c6b0);
          font-weight: bold;
          letter-spacing: 1px;
          font-size: 14px;
          transform: translateY(-10px);
        }
      }

      .close {
        position: absolute;
        right: 12px;
        top: 12px;
        width: calc(30px);
        height: calc(30px);
        background-size: $bs !important;
      }
    }
  }
}

// PC
@media screen and (min-width: 1400px) {
  .shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 99;

    .box {
      position: relative;
      width: calc(100% - 79px * 2);
      height: calc(100% - 27px * 2);
      border-radius: 12px;
      background: #0c2033;
      border: 1px solid #39c6b0;
      border-bottom: none;
      overflow: hidden;

      .title {
        font-size: 18px;
        color: #fff;
        margin: 13px 0 5px 17px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .scene-protect {
        display: flex;
        flex-wrap: wrap;
        width: calc(268px - 6px);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100% - 50px);
        padding-left: 10px;

        &::-webkit-scrollbar {
          width: 2px;
          height: 10px;
        }
        /* 设置滚动条的轨道 */
        &::-webkit-scrollbar-track {
          background: #0c2033;
        }
        /* 设置滚动条里面的小方块 */
        &::-webkit-scrollbar-thumb {
          background: rgba($color: #b3b9bc, $alpha: 0.3);
          border-radius: 10px;
        }

        .scene {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: calc(114px);
          height: calc(58px);
          border-radius: 8px;
          margin: 6px 0 0 6px;
          background: rgba($color: #fff, $alpha: 0.1);
          border: 1px solid transparent;

          .sceneImg {
            width: calc(46px * 0.8);
          }

          span {
            color: #ebebeb;
            font-size: 14px;
            transform: scale(0.8) translateY(-4px);
          }

          .current {
            position: absolute;
            right: 3px;
            bottom: 3px;
            width: 20px;
          }
        }

        .check {
          border: 1px solid #fff;
          background: linear-gradient(to left, #236fc6, #39c6b0);
        }

        .noopen {
          border: 1px solid #fff;
        }
      }

      .bigImg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 272px);
        height: calc(100%);
        background: linear-gradient(to bottom, #0e253f, #0e3f3f);

        .building {
          color: #aecad5;
        }

        img {
          width: calc(664px * 0.3);
          height: calc(664px * 0.3);
        }

        .deliver {
          width: calc(235px);
          height: calc(40px);
          border-radius: 8px;
          border: 0;
          outline: 0;
          color: #fff;
          background: linear-gradient(to right, #39c6b0 0%, #236fc6 50%, #39c6b0);
          font-weight: bold;
          letter-spacing: 1px;
          font-size: 14px;
          transform: translateY(-10px);
        }
      }

      .close {
        position: absolute;
        right: 12px;
        top: 12px;
        width: calc(30px);
        height: calc(30px);
        background-size: $bs !important;
      }
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 99;

    .box {
      position: relative;
      width: calc(100% - 34px * 2);
      height: calc(100% - 67px * 2);
      border-radius: 12px;
      background: #0c2033;
      border: 1px solid rgba(57, 198, 176, 0.7);

      .title {
        font-size: 18px;
        color: #fff;
        margin: 13px 0 5px 17px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .scene-protect {
        display: flex;
        flex-wrap: wrap;
        width: calc(228px + 18px + 4px);
        max-height: calc(55% - 18px - 18px - 20px - 20px);
        overflow-y: auto;
        overflow-x: hidden;
        margin: 12px auto;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
          width: 2px;
          height: 10px;
        }
        /* 设置滚动条的轨道 */
        &::-webkit-scrollbar-track {
          background: #0c2033;
        }
        /* 设置滚动条里面的小方块 */
        &::-webkit-scrollbar-thumb {
          background: rgba($color: #b3b9bc, $alpha: 0.3);
          border-radius: 10px;
        }

        .scene {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: calc(114px);
          height: calc(80px);
          border-radius: 8px;
          margin: 6px 0 0 6px;
          background: rgba($color: #fff, $alpha: 0.1);
          border: 1px solid rgba(255, 255, 255, 0.15);

          .sceneImg {
            width: calc(46px);
          }

          span {
            color: #ebebeb;
            font-size: 14px;
          }

          .current {
            position: absolute;
            right: 1px;
            bottom: 5px;
            width: 20px;
          }
        }

        .check {
          border: 1px solid #fff;
          background: linear-gradient(to left, #236fc6, #39c6b0);
        }

        .noopen {
          border: 1px solid #fff;
        }
      }

      .bigImg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100%);
        height: calc(45%);
        background: linear-gradient(to bottom, #0e253f, #0e3f3f);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .building {
          color: #9c9c9c;
          font-size: 16px;
        }

        .buildname {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(173px);
          height: calc(25px);
          background-size: $bs !important;
          color: #aecad5;
          font-size: 12px;
          margin: 35px 0 0 0;
        }

        .buildintro {
          color: #aecad5;
          margin: 6px 32px 0 32px;
        }

        img {
          width: calc(664px * 0.3);
          height: calc(664px * 0.3);
        }

        .deliver {
          width: calc(235px);
          height: calc(40px);
          border-radius: 8px;
          border: 0;
          outline: 0;
          color: #fff;
          background: linear-gradient(to right, #39c6b0 0%, #236fc6 50%, #39c6b0);
          font-weight: bold;
          letter-spacing: 1px;
          font-size: 14px;
          transform: translateY(-10px);
        }
      }

      .close {
        position: absolute;
        right: 12px;
        top: 12px;
        width: calc(30px);
        height: calc(30px);
        background-size: $bs !important;
      }
    }
  }
}
</style>
