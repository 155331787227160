var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('XyzTransition',{attrs:{"xyz":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$store.state.playVideoName && _vm.$store.state.videoSlowShow && !_vm.$store.state.videoIntroShow
      ),expression:"\n        $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow\n      "}],staticClass:"back"},[_c('div',{staticClass:"reback",style:({
          background: ("url(" + (require('@assets/img/exit.png')) + ") no-repeat")
        }),on:{"click":_vm.close}}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.$store.state.playVideoName))])])]),_c('XyzTransition',{attrs:{"xyz":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$store.state.playVideoName && _vm.$store.state.videoSlowShow && !_vm.$store.state.videoIntroShow
      ),expression:"\n        $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow\n      "}],staticClass:"progress"},[_c('div',{staticClass:"playandpause",style:({
          background: ("url(" + (_vm.$store.state.videoplayState === 1
              ? require('@assets/img/pause.png')
              : require('@assets/img/play.png')) + ") no-repeat")
        }),on:{"click":_vm.playandpause}}),_c('div',{staticClass:"probox"},[_c('el-slider',{attrs:{"show-tooltip":false},on:{"change":_vm.change},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}})],1),_c('div',{staticClass:"duration"},[_vm._v(_vm._s(_vm.$store.state.duration))])])]),_c('XyzTransition',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$store.state.playVideoName && _vm.$store.state.videoSlowShow && !_vm.$store.state.videoIntroShow
    ),expression:"\n      $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow\n    "}],attrs:{"xyz":"fade"}},[_c('div',{staticClass:"introduce",style:({
        background: ("url(" + (require('@assets/img/简介@2x.png')) + ") no-repeat")
      }),on:{"click":function($event){return _vm.$store.commit('setVideoIntroShow', true)}}},[_c('span',[_vm._v("简介")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.videoplayState === 1),expression:"$store.state.videoplayState === 1"}],staticClass:"json360",attrs:{"id":"json360"}}),_c('XyzTransition',{attrs:{"xyz":_vm.$store.state.chat.yaoganWatch === 2 ? 'fade right' : 'fade down'}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.videoIntroShow),expression:"$store.state.videoIntroShow"}],staticClass:"shadow",on:{"click":function($event){return _vm.$store.commit('setVideoIntroShow', false)}}},[_c('div',{staticClass:"cebian",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"title"},[_vm._v("视频简介")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"contentitem"},[_vm._v(" "+_vm._s(_vm.$store.state.intro[_vm.$store.state.playVideoName])+" ")])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$store.state.playVideoName && _vm.$store.state.videoSlowShow && !_vm.$store.state.videoIntroShow
    ),expression:"\n      $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow\n    "}],staticClass:"shadowtop"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$store.state.playVideoName && _vm.$store.state.videoSlowShow && !_vm.$store.state.videoIntroShow
    ),expression:"\n      $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow\n    "}],staticClass:"shadowbottom"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }