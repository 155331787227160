<template>
  <div>
    <XyzTransition xyz="fade">
      <div class="centerEnter" @click="openCenter" v-if="!$store.state.shotShow">
        <img :src="require(`@assets/img/role/${$store.state.chat.role.black}.png`)" alt="" />
        <div class="name">
          {{ $store.state.chat.role.name }}
        </div>
      </div>
    </XyzTransition>

    <XyzTransition xyz="fade">
      <div
        v-if="!$store.state.shotShow"
        class="deliver"
        :style="{
          background: `url('${
            hover.deliver
              ? require('@assets/img/send-s-hover.png')
              : require('@assets/img/send-s.png')
          }') no-repeat`
        }"
        @touchstart="hover.deliver = true"
        @touchend="hover.deliver = false"
        @click="openMap"
      ></div>
    </XyzTransition>

    <div class="functions">
      <div
        class="functions-item"
        :class="{
          'functions-item-showopen': $store.state.shotShow
        }"
        v-for="(item, i) in $store.state.shotShow ? shotList : imgList"
        :key="i"
        @click="openFun(item)"
      >
        <img :src="$store.state[item.name] ? item.hover : item.src" alt="" />
        <span>{{ item.explain }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageBtns',

  data() {
    return {
      // hover效果
      hover: {
        deliver: false,
        face: false,
        shot: false,
        action: false,
        set: false
      },
      imgList: [
        {
          name: 'shotShow',
          explain: '拍照',
          src: require('@assets/img/shot.png'),
          hover: require('@assets/img/shot-hover.png')
        },
        {
          name: 'actionShow',
          explain: '动作',
          src: require('@assets/img/action.png'),
          hover: require('@assets/img/action-hover.png')
        },
        {
          name: 'setShow',
          explain: '设置',
          src: require('@assets/img/set.png'),
          hover: require('@assets/img/set-hover.png')
        }
      ],
      shotList: [
        {
          name: 'actionShow',
          explain: '动作',
          src: require('@assets/img/action.png'),
          hover: require('@assets/img/action-hover.png')
        }
      ]
    }
  },

  mounted() {},

  methods: {
    openCenter() {
      if (this.$store.state.chat.role.type === 'guest') {
        this.$store.commit('changeGuestTip', true)
        return
      }

      this.Bus.$emit('changeName')
      this.$store.commit('iniyxulieCurrent')
      this.$store.commit('setName', this.$store.state.chat.role.name)
      this.$store.commit('setCurrentAvatarSelect', this.$store.state.chat.role.black)
      this.$store.commit('changeCenterShow', true)
    },
    openMap() {
      this.$store.commit('changeMapShow2', true)
      setTimeout(() => {
        this.$store.commit('changeMapShow', true)
      }, 0)
    },
    openFun(item) {
      if (item.name !== 'setShow' && this.$store.state.chat.role.type === 'guest') {
        this.$store.commit('changeGuestTip', true)
        return
      }

      switch (item.name) {
        case 'shotShow':
          this.$store.commit('changeActionShow', false)
          this.$store.commit('changeSetShow', false)
          this.$store.commit('changeShotShow', true)
          break
        case 'actionShow':
          this.$store.commit('changeActionShow', !this.$store.state.actionShow)
          break
        case 'setShow':
          this.$store.commit('changeShotShow', false)
          this.$store.commit('changeActionShow', false)
          this.$store.commit('changeSetShow', true)
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.centerEnter {
  position: fixed;
  left: 40px;
  top: 20px;
  width: 128px;
  height: 32px;
  z-index: 13;

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 31.5px;
    height: 31.5px;
    border-radius: 50%;
    // border: 1px solid #fff;
    background: #e8c980;
    z-index: 15;
  }

  .name {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 calc(16px + 10px);
    height: 31.5px;
    z-index: 14;
    background: linear-gradient(to right, #122336, rgba($color: #000000, $alpha: 0));
    color: #fff;
    font-size: 12px;
    line-height: 32px;
    text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.3);
    white-space: nowrap;
  }
}

.deliver {
  position: fixed;
  right: 26px;
  top: 10px;
  z-index: 13;
  width: 84px;
  height: 84px;
  background-size: $bs !important;
}

.functions {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: calc(26px + 42px - 19px);
  top: calc(84px + 15px);
  z-index: 13;
  width: 34px;

  .functions-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    img {
      width: 34px;
    }

    span {
      color: #fff;
      text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.3);
    }
  }

  .functions-item-showopen {
    position: fixed;
    right: 56px;
    bottom: 28px;
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .centerEnter {
    left: 20px;
    top: 31px;
  }

  .deliver {
    top: initial;
    right: 15px;
    bottom: 10px;
  }

  .functions {
    right: calc(84px + 26px + 10px);
    top: initial;
    bottom: calc(42px + 10px - 30px);
    flex-direction: row;
    width: auto;
    height: auto;

    .functions-item {
      margin-top: 0;
      margin-left: 20px;
    }

    .functions-item-showopen {
      right: 28px;
      bottom: 27px;
    }
  }
}
</style>
