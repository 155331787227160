<template>
  <div>
    <XyzTransition xyz="fade">
      <div
        class="center"
        :style="{
          background: `url('${
            $store.state.chat.yaoganWatch === 4
              ? require('@assets/img/center2.png')
              : require('@assets/img/center.png')
          }') no-repeat`,
          'background-size': $store.state.chat.yaoganWatch === 4 ? '100% auto' : '100% 100%'
        }"
        v-show="$store.state.centerShow"
      >
        <div
          class="back"
          :style="{
            background: `url('${require('@assets/img/back.png')}') no-repeat`
          }"
          id="back"
          @click="back"
        >
          <span>形象设置</span>
        </div>

        <div class="intro" id="intro">
          <div class="title">
            <span>{{ avatarList[$store.state.currentAvatarSelect - 1].name }}</span>
            <img class="logo" src="@assets/img/logo.png" alt="" />
          </div>
          <div class="green"></div>
          <div class="content">{{ avatarList[$store.state.currentAvatarSelect - 1].content }}</div>
          <div class="decorate">
            <div class="xie"></div>
            <div class="xie"></div>
            <div class="xie"></div>
            <div class="box"></div>
          </div>
        </div>

        <div
          class="man"
          id="man"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
        >
          <img :src="rebackImg()" alt="" />
        </div>

        <div class="selectAvatar">
          <div class="head">选择形象</div>
          <div class="avatarBox">
            <div
              class="avatar"
              v-for="(item, i) in avatarList"
              :key="i"
              :style="{
                background:
                  i + 1 === $store.state.currentAvatarSelect
                    ? 'linear-gradient(to right, #39c6b0, #236fc6)'
                    : ''
              }"
              @click="changeRole(i)"
            >
              <img
                :style="{
                  width: '50px',
                  height: '50px'
                }"
                :src="item.src"
                alt=""
              />
            </div>
          </div>

          <div class="setName">
            <div class="head2">设置昵称</div>
            <div class="name">
              <input
                id="inputBox"
                type="text"
                class="nameinput"
                maxlength="8"
                v-model="name"
                @input="inputChange"
              />
              <div
                class="touzi"
                @click="randomName"
                @touchstart="hover.touzi = true"
                @touchend="hover.touzi = false"
              >
                <img
                  :src="
                    hover.touzi
                      ? require('@assets/img/touzi-hover.png')
                      : require('@assets/img/touzi.png')
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import { Surname, Name } from '@assets/config/randomName.js'

export default {
  data() {
    return {
      avatarList: [
        {
          id: 0,
          src: require('@assets/img/role/boy1/boy_01.png'),
          name: '学者',
          content:
            '他对于各个时期的历史事件和人物都有深入的了解。性格温和而谦逊，他善于将复杂的历史概念和事件解释得通俗易懂，让人们更好地理解历史的重要性和影响。'
        },
        {
          id: 1,
          src: require('@assets/img/role/boy2/Boy_02.png'),
          name: '工程师',
          content:
            '他在虚拟数字世界中拥有自己的科技工作室，致力于研发和分享最新的科技进展。他善于解决技术难题，乐于与其他科技爱好者合作，共同探索虚拟世界中的技术应用。他的目标是推动科技与社会的融合，为人们带来更好的生活体验。'
        },
        {
          id: 2,
          src: require('@assets/img/role/boy3/Boy_03.png'),
          name: '摄影师',
          content:
            '他善于捕捉瞬间的美和情感，通过镜头展现出世界的多样性和独特之处。他个性独立而冒险，喜欢到世界各地寻找灵感，用照片讲述故事。他在虚拟社交应用中展示自己的摄影作品，并与其他摄影爱好者分享技巧和经验。他追求自我成长和创新，不断探索摄影领域的新趋势和技术，他的作品鼓励人们重新审视世界，发现平凡事物中的美丽。'
        },
        {
          id: 3,
          src: require('@assets/img/role/girl1/Girl_010000.png'),
          name: '作家',
          content:
            '她敏锐的观察力和丰富的想象力使她创作出富有情感和深度的文学作品。她性格开朗、乐观，鼓励人们通过文字表达自己的情感和思考，并通过虚拟社交应用与其他作家和读者分享作品和创作心得。'
        },
        {
          id: 4,
          src: require('@assets/img/role/girl2/Girl_02.png'),
          name: '记者',
          content:
            '她在虚拟数字世界中致力于传播社会正能量和推动社会变革。她积极参与虚拟社区中的公益活动，组织讨论会和活动，鼓励人们关注环境、人权、教育等重要议题。她希望通过虚拟社交应用，激发更多人参与社会公益，共同创造一个更美好的世界。'
        },
        {
          id: 5,
          src: require('@assets/img/role/girl3/Girl_03.png'),
          name: '导演',
          content:
            '她对电影艺术充满热情，通过她的作品探索人性和社会议题。她勇于表达自我，在虚拟数字世界中分享自己的电影作品，引发观众对于生活和人类经验的思考。她富有创造力和洞察力，不断挑战传统观念，探索电影艺术的边界，并与其他电影人交流合作，共同推动电影行业的发展。'
        }
      ],
      name: '',
      hover: {
        touzi: false
      },
      startX: 0, // 手指起始位置的横坐标
      moveX: 0, // 手指移动的距离
      idx: 35,
      startIdx: 0
    }
  },
  methods: {
    randomName() {
      let name = Surname[this.$lyh.createRandom(Surname)] + Name[this.$lyh.createRandom(Name)]
      this.name = name
      this.$store.commit('setName', name)
    },
    inputChange() {
      this.name = this.name.replace(/[^\u4E00-\u9FA5]/g, '').trim()
      this.$store.commit('setName', this.name)
    },
    back() {
      if (
        this.name !== this.$store.state.chat.role.name ||
        this.$store.state.currentAvatarSelect !== this.$store.state.chat.role.black
      ) {
        this.$store.commit('changeOpenModalShowInfo', {
          title: '是否要保存形象和昵称？',
          ok: '确认',
          cancel: '取消',
          function: 'save'
        })
        this.$store.commit('changeOpenModalShow', true)
      } else {
        this.$store.commit('changeCenterShow', false)
      }

      this.idx = 35
    },
    onTouchStart(event) {
      this.startX = event.touches[0].clientX
      this.moveX = 0
      this.startIdx = this.idx
    },
    onTouchEnd() {},
    onTouchMove(event) {
      this.moveX = event.touches[0].clientX - this.startX
      let rate = this.moveX / window.innerWidth
      this.idx = this.startIdx - Math.floor((rate * 180 * 4) / 10)
      if (this.idx < 0) {
        this.idx = -this.idx
        this.idx %= 36
        this.idx = 35 - this.idx
      } else {
        this.idx %= 36
      }

      switch (this.$store.state.currentAvatarSelect) {
        case 1:
          this.$store.commit('changexulieCurrent', {
            type: 'boy1',
            val: this.idx
          })
          break
        case 2:
          this.$store.commit('changexulieCurrent', {
            type: 'boy2',
            val: this.idx
          })
          break
        case 3:
          this.$store.commit('changexulieCurrent', {
            type: 'boy3',
            val: this.idx
          })
          break
        case 4:
          this.$store.commit('changexulieCurrent', {
            type: 'girl1',
            val: this.idx
          })
          break
        case 5:
          this.$store.commit('changexulieCurrent', {
            type: 'girl2',
            val: this.idx
          })
          break
        case 6:
          this.$store.commit('changexulieCurrent', {
            type: 'girl3',
            val: this.idx
          })
          break
      }
    },
    rebackImg() {
      switch (this.$store.state.currentAvatarSelect) {
        case 1:
          return this.$store.state.xulie.boy1[this.$store.state.xulieCurrent.boy1]
        case 2:
          return this.$store.state.xulie.boy2[this.$store.state.xulieCurrent.boy2]
        case 3:
          return this.$store.state.xulie.boy3[this.$store.state.xulieCurrent.boy3]
        case 4:
          return this.$store.state.xulie.girl1[this.$store.state.xulieCurrent.girl1]
        case 5:
          return this.$store.state.xulie.girl2[this.$store.state.xulieCurrent.girl2]
        case 6:
          return this.$store.state.xulie.girl3[this.$store.state.xulieCurrent.girl3]
      }
    },
    changeRole(i) {
      this.idx = 35
      this.$store.commit('setCurrentAvatarSelect', i + 1)
    }
  },
  created() {
    this.Bus.$on('changeName', () => {
      this.name = this.$store.state.chat.role.name
    })
  },
  mounted() {
    const input = document.getElementById('inputBox')

    if (navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1) {
      return
    }

    var keyboardHeight = 0,
      screenHeight = window.innerHeight

    window.addEventListener(
      'orientationchange',
      () => {
        if (
          navigator.userAgent.indexOf('Windows') == -1 &&
          (window.orientation == 90 || window.orientation == -90)
        ) {
          screenHeight = window.screen.height - 91
        }
        if (
          navigator.userAgent.indexOf('Windows') == -1 &&
          (window.orientation == 180 || window.orientation == 0)
        ) {
          screenHeight = window.screen.height - 91
        }
      },
      false
    )

    // 监听输入框的focus事件
    input.addEventListener('focus', () => {
      if (this.$store.state.chat.yaoganWatch === 4) {
        setTimeout(() => {
          keyboardHeight = screenHeight - window.innerHeight
          // 获取样式表对象
          var sheet_s = document.styleSheets[0]

          // var rule_1 = `.my-class5 { background-size: auto 100% !important; }`
          var rule_2 = `.my-class6 { right: -${keyboardHeight - 40}px !important; }`

          // 添加新的CSS规则
          if (sheet_s.insertRule) {
            // sheet_s.insertRule(rule_1, 0)
            sheet_s.insertRule(rule_2, 0)
          }

          // document.getElementsByClassName('center')[0].classList.add('my-class5')
          document.getElementsByClassName('selectAvatar')[0].classList.add('my-class6')
        }, 300)
        return
      } else {
        setTimeout(() => {
          var keyboardHeight = screenHeight - window.innerHeight

          // 获取样式表对象
          var sheet = document.styleSheets[0]
          // 创建新的 CSSStyleRule 对象
          var rule = `.my-class { top: -${keyboardHeight}px !important; }`
          var rule2 = `.my-class2 { top: -${keyboardHeight - 55}px !important; }`
          var rule3 = `.my-class3 { top: -${keyboardHeight - 20}px !important; }`
          var rule4 = `.my-class4 { background-size: 100% auto !important; }`

          // 添加新的CSS规则
          if (sheet.insertRule) {
            sheet.insertRule(rule, 0)
            sheet.insertRule(rule2, 0)
            sheet.insertRule(rule3, 0)
            sheet.insertRule(rule4, 0)
          }

          document.getElementsByClassName('center')[0].classList.add('my-class4')
          document.getElementById('back').classList.add('my-class3')
          document.getElementById('intro').classList.add('my-class2')
          document.getElementById('man').classList.add('my-class')
          document.getElementsByClassName('selectAvatar')[0].classList.add('my-class')
        }, 300)
      }
    })

    // 监听输入框的blur事件
    input.addEventListener('blur', () => {
      if (this.$store.state.chat.yaoganWatch === 4) {
        document.getElementsByClassName('center')[0].classList.remove('my-class5')
        document.getElementsByClassName('selectAvatar')[0].classList.remove('my-class6')
        return
      } else {
        document.getElementsByClassName('center')[0].classList.remove('my-class4')
        document.getElementById('back').classList.remove('my-class3')
        document.getElementById('intro').classList.remove('my-class2')
        document.getElementById('man').classList.remove('my-class')
        document.getElementsByClassName('selectAvatar')[0].classList.remove('my-class')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  .back {
    position: fixed;
    left: 30px;
    top: 20px;
    width: 24px;
    height: 24px;
    filter: brightness(0);
    background-size: $bs !important;

    span {
      position: absolute;
      right: -80px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: #333;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .intro {
    position: fixed;
    left: 35px;
    top: 55px;
    width: calc(254px - 30px);
    height: calc(235px - 32px);
    border-top: 1px solid #39c6b0;
    background: rgba($color: #fbfbfb, $alpha: 0.3);
    padding: 15px 16px;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 1px;
      height: 100%;
      background: linear-gradient(to bottom, #39c6b0 40%, #8dd8d5 50%, rgba(255, 255, 255, 0.1));
    }

    &::after {
      content: '';
      position: absolute;
      right: -1px;
      top: -1px;
      width: 1px;
      height: 100%;
      background: linear-gradient(to bottom, #39c6b0 40%, #8dd8d5 50%, rgba(255, 255, 255, 0.1));
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-size: 16px;
      font-weight: bold;

      img {
        width: 30px;
      }
    }

    .green {
      width: 30px;
      height: 4px;
      background: linear-gradient(to bottom right, #39c6b0, #2a88c0);
      margin: 10px 0;
    }

    .content {
      color: #516261;
      font-size: 12px;
    }

    .decorate {
      display: flex;
      position: absolute;
      right: 19px;
      bottom: 15px;
      width: 100px;
      height: 4px;
      background: #fff;

      .xie {
        width: 3px;
        height: 4px;
        background: #fff;
        margin-left: 2px;
        transform: translateX(-14px) skew(30deg);
      }
    }
  }

  .man {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 224px - 256px - 120px);
    height: 100vh;

    img {
      width: 100%;
    }
  }

  .selectAvatar {
    position: fixed;
    top: 0;
    right: 40px;
    width: calc(76px * 3 + 14px * 2);
    height: 100%;

    .head {
      color: #3f3f3f;
      font-size: 14px;
      margin: 55px 0 8px 0;
    }

    .avatarBox {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      height: calc(110px + 13px);

      .avatar {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 76px;
        height: 55px;
        background-size: $bs !important;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1));
        border-radius: 8px;
      }
    }

    .setName {
      margin-top: 17px;

      .head2 {
        color: #3f3f3f;
        font-size: 14px;
        margin-bottom: 4px;
      }

      .name {
        display: flex;
        border: 1px solid #fff;
        border-radius: 8px;
        height: 42px;
        background: rgba($color: #ffffff, $alpha: 0.3);

        .nameinput {
          padding-left: 12px;
          width: calc(100% - 30px - 12px);
          background: transparent;
          border: 0;
          outline: 0;
          font-size: 14px;
          color: #333;
        }

        .touzi {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 30px;
          background-size: $bs !important;
          border-radius: 6px;

          img {
            width: 23px;
            height: 23px;
          }
        }
      }
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .center {
    overflow-y: auto;
    overflow-x: hidden;

    .back {
      top: 30px;
    }

    .man {
      position: absolute;
      top: 70px;
      left: 50%;
      width: 80%;
      height: 320px;
      transform: translateX(-50%);

      img {
        width: 100%;
      }
    }

    .intro {
      position: absolute;
      top: 710px;
      left: 50%;
      transform: translateX(-50%);
      // top: 90px;
    }

    .selectAvatar {
      position: absolute;
      top: 320px;
      left: 50%;
      transform: translateX(-50%);

      .head {
        margin: 90px 0 12px 0;
      }

      .setName {
        margin-top: 27px;

        .head2 {
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
