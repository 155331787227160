<template>
  <div>
    <XyzTransition xyz="fade">
      <div class="shadow" @click="close" v-show="$store.state.guestTip">
        <div class="box" @click.stop="">
          <img class="lion" src="@assets/img/hi.png" alt="" />
          <img class="star" src="@assets/img/star.png" alt="" />
          <div
            class="close"
            :style="{
              background: `url('${require('@assets/img/close.png')}') no-repeat`
            }"
            @click="close"
          ></div>
          <span class="txt">此功能需要登录后才可使用</span>
          <div
            class="login"
            :style="{
              background: `url('${
                hover ? require('@assets/img/tipbtn-hover.png') : require('@assets/img/tipbtn.png')
              }') no-repeat`
            }"
            @touchstart="hover = true"
            @touchend="hover = false"
            @click="login"
          >
            立即登录
          </div>
        </div>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
export default {
  name: 'guestTips',

  data() {
    return {
      hover: false
    }
  },

  mounted() {},

  methods: {
    close() {
      this.$store.commit('changeGuestTip', false)
    },
    login() {
      this.$store.commit('clearToken')
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 99;

  .box {
    position: relative;
    width: 357px;
    height: 163px;
    border-radius: 16px;
    background: linear-gradient(to bottom, #ffedc4 1%, #ffffff 99%);

    .lion {
      position: absolute;
      left: -30px;
      top: -48px;
      width: calc(294px * 0.5);
    }

    .star {
      position: absolute;
      right: 20px;
      top: 0;
      width: calc(138px * 0.5);
    }

    .close {
      position: absolute;
      right: -30px;
      top: -30px;
      width: calc(48px * 0.6);
      height: calc(48px * 0.6);
      background-size: $bs !important;
    }

    .txt {
      position: absolute;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
    }

    .login {
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
      width: calc(338px * 0.5);
      height: calc(111px * 0.5);
      line-height: calc(111px * 0.5);
      text-align: center;
      background-size: $bs !important;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .shadow {
    .box {
      width: 307px;
      height: 163px;

      .lion {
        left: -20px;
        top: -40px;
        width: calc(119px);
      }

      .star {
        width: calc(53px);
      }

      .close {
        right: -25px;
        top: -25px;
        width: calc(24px);
        height: calc(24px);
      }

      .txt {
        left: 53%;
      }
    }
  }
}
</style>
