<template>
  <div class="yaogan" id="yaogan" @touchstart="start">
    <img
      class="gan"
      :src="hover ? require('@assets/img/yaogan-hover.png') : require('@assets/img/yaogan.png')"
      :style="`left:${left}px;top:${top}px`"
    />

    <img
      v-show="hover"
      class="quan"
      src="@assets/img/quan.png"
      alt=""
      :style="{
        transform: 'rotate(' + rotate + 'deg)'
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'yaogan',
  data() {
    return {
      left: 0,
      top: 0,
      position: {
        x: 0,
        y: 0
      },
      hover: false,
      rotate: 0
    }
  },
  methods: {
    calDeg(bx, by) {
      // 假设 A 点为圆心所在的垂直方向
      let center = { x: 0, y: 0 } // 圆心

      const radius = 110 / 2 // 圆的半径

      if (document.o2.cameraControls.orientation === 'landscape') {
        center.x = 30 + 110 / 2
        center.y = 65 + 110 / 2
      } else {
        center.x = 65 + 110 / 2
        center.y = window.innerHeight - 30 - 110 / 2
      }

      let pointA = { x: center.x, y: center.y - radius } // 圆外圈垂直正上方的点A
      let pointB = { x: bx, y: by }
      // 计算向量 OA 和 OB
      const vectorOA = { x: pointA.x - center.x, y: pointA.y - center.y }
      const vectorOB = { x: pointB.x - center.x, y: pointB.y - center.y }

      // 计算向量 OA 和 OB 的夹角
      const dotProduct = vectorOA.x * vectorOB.x + vectorOA.y * vectorOB.y
      const magnitudeOA = Math.sqrt(vectorOA.x * vectorOA.x + vectorOA.y * vectorOA.y)
      const magnitudeOB = Math.sqrt(vectorOB.x * vectorOB.x + vectorOB.y * vectorOB.y)
      const angleInRadians = Math.acos(dotProduct / (magnitudeOA * magnitudeOB))
      let angleInDegrees = (angleInRadians * 180) / Math.PI

      if (document.o2.cameraControls.orientation === 'landscape') {
        // 找出距离 A 点顺时针旋转到 B 点的夹角
        if (pointB.x < center.x) {
          // B点在圆心左侧
          angleInDegrees = 360 - angleInDegrees
          this.rotate = parseInt(angleInDegrees) - 90
        } else {
          this.rotate = parseInt(angleInDegrees) - 90
        }
      } else {
        // 找出距离 A 点顺时针旋转到 B 点的夹角
        if (pointB.x < center.x) {
          // B点在圆心左侧
          angleInDegrees = 360 - angleInDegrees
          this.rotate = parseInt(angleInDegrees)
        } else {
          this.rotate = parseInt(angleInDegrees)
        }
      }
    },
    start(e) {
      this.hover = true

      if (!e.touches[0].target.src && !e.touches[0].target.id) {
        this.position.x = e.touches[e.touches.length - 1].clientX
        this.position.y = e.touches[e.touches.length - 1].clientY
      } else {
        this.position.x = e.touches[0].clientX
        this.position.y = e.touches[0].clientY
      }

      document.getElementById('yaogan').addEventListener('touchmove', this.move)
      document.getElementById('yaogan').addEventListener('touchend', this.end)
    },
    move(e) {
      e.preventDefault()

      if (!e.touches[0].target.src && !e.touches[0].target.id) {
        this.position.x = e.touches[e.touches.length - 1].clientX
        this.position.y = e.touches[e.touches.length - 1].clientY
      } else {
        this.position.x = e.touches[0].clientX
        this.position.y = e.touches[0].clientY
      }

      this.calDeg(this.position.x, this.position.y)
    },
    end() {
      this.hover = false

      document.getElementById('yaogan').removeEventListener('touchmove', this.move)

      this.left = 0
      this.top = 0
      this.position = {
        x: 0,
        y: 0
      }

      // Bus.$emit('key', 'all')
    }
  },
  created() {},
  watch: {
    'position.x': {
      deep: true,
      handler: function(newV, oldV) {
        if (newV != 0 && oldV) {
          var x = newV - oldV

          if (this.left > 65) this.left = 65
          else if (this.left < -75) this.left = -75
          else if (x) this.left += x
        }

        document.o2play.yaogan(this.left, this.top)
      }
    },

    'position.y': {
      deep: true,
      handler: function(newV, oldV) {
        if (newV != 0 && oldV) {
          var y = newV - oldV

          if (this.top > 75) this.top = 75
          else if (this.top < -75) this.top = -75
          else if (y) this.top += y
          // console.log(this.top, '当前到中心点的y轴距离')

          document.o2play.yaogan(this.left, this.top)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
// 移动端 横屏
@media screen and (max-width: 999px) and (max-height: 500px) {
  .yaogan {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: url('../../assets/img/yaoganbg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 65px;
    bottom: 30px;
    z-index: 10;

    .gan {
      position: absolute;
      width: 70%;
      height: 70%;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: 0.1s;
    }

    .quan {
      position: absolute;
      width: calc(110px * 1.35);
      height: calc(110px * 1.35);
      left: calc(50% - 110px * 1.35 * 0.5);
      top: calc(50% - 110px * 1.35 * 0.5);
      transform: 0.1s;
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .yaogan {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: url('../../assets/img/yaoganbg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 30px;
    bottom: 81px;
    z-index: 10;

    .gan {
      position: absolute;
      width: 70%;
      height: 70%;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: 0.1s;
    }

    .quan {
      position: absolute;
      width: calc(110px * 1.35);
      height: calc(110px * 1.35);
      left: calc(50% - 110px * 1.35 * 0.5);
      top: calc(50% - 110px * 1.35 * 0.5);
      transform: 0.1s;
    }
  }
}
</style>
