<template>
  <div>
    <o2vrWebUi />
    <div id="o2vr_container_fenghuang"></div>
    <loading v-if="$store.state.chat.loadingSwitch" />

    <t-dialog
      :visible.sync="$store.state.chat.sitShow"
      attach="body"
      header="输入座位名称"
      destroyOnClose
      :onConfirm="confirm"
      :onClose="close"
    >
      <div slot="body">
        <div>
          <t-input :onEnter="confirm" v-model="text" placeholder="请输入名称" :maxlength="20" />
        </div>
      </div>
    </t-dialog>

    <t-dialog
      :visible.sync="$store.state.chat.streamShow"
      attach="body"
      header="直播流推送"
      destroyOnClose
      :onConfirm="sendStream"
      :onClose="closeStream"
    >
      <div slot="body">
        <div>
          <t-input
            :onEnter="sendStream"
            v-model="stream"
            placeholder="请输入直播流地址(移动端设备仅支持m3u8)"
          />
          <br />
          <t-button @click="endStream">结束流</t-button>
        </div>
      </div>
    </t-dialog>

    <t-dialog
      :visible.sync="$store.state.chat.editAuthShow"
      attach="body"
      header="修改身份"
      destroyOnClose
      :onConfirm="editAuth"
      :onClose="closeEditAuthShow"
    >
      <div slot="body">
        <div>
          <t-input :onEnter="editAuth" v-model="userId" placeholder="请输入用户Id" />
          <br />
          <t-select v-model="auth" :options="options" placeholder="请选择" />
        </div>
      </div>
    </t-dialog>

    <!-- scene1 -->
    <audio
      v-if="$store.state.initConfig.sceneList[$store.state.sceneCurrentIndex].music"
      :src="$store.state.initConfig.sceneList[$store.state.sceneCurrentIndex].music.bgm"
      id="bgm"
      controls
      style="display:none"
    ></audio>
    <audio
      v-if="$store.state.initConfig.sceneList[$store.state.sceneCurrentIndex].music.sound"
      :src="
        $store.state.initConfig.sceneList[$store.state.sceneCurrentIndex].music.sound[
          $store.state.currentSound
        ].bgm
      "
      id="sound"
      style="display:none"
      controls
    ></audio>

    <audio id="touchbgm" controls style="display:none" :src="$store.state.touchBgm"></audio>
  </div>
</template>

<script>
import { userInfo, add_sit, sit, edit } from '../assets/js/api'
import loading from '@/components/pageFunc/loading.vue'
import o2vrWebUi from '@/components/function.vue'

export default {
  components: {
    loading,
    o2vrWebUi
  },
  async beforeCreate() {
    // this.$store.commit('clearToken')

    if (this.$store.state.token.includes('guest')) return

    const res = await userInfo()
    if (res.code == 0) {
      // const sits = await sit() // 获取座位信息
      // // 座位信息
      // this.$store.commit('chat/setSits', sits.data)
      const role = res.data
      role.black = role.black ? role.black : 1
      role.auth = role.auth ? role.auth : 0
      this.$store.commit('chat/setRole', role)
      this.$store.commit('chat/setModelIndex', res.data.black)
      console.log(this.$store.state.chat.role)

      // 创建oss client
      // this.$o2Function.createClient()

      document.o2.multiplayer.onPlayerLoaded = (p) => {
        if (p == document.o2.role) {
          this.$o2Init.standAndUp()
        }
      }

      try {
        try {
          const config = [
            this.$store.state.websocket,
            {
              name: this.$store.state.chat.role.name,
              id: this.$store.state.chat.role.id,
              auth: this.$store.state.chat.role.auth,
              info: {
                modelIdx: this.$store.state.chat.role.black
              }
            },
            this.$store.state.roomId
          ]

          this.$store.commit('chat/changeLoading', true)

          await this.$lyh.init(config, this)
        } catch (error) {
          throw error
        }
      } catch (error) {
        this.$message.error(error)
      }
    }
  },
  async mounted() {
    document.getElementById('o2vr_container_fenghuang').addEventListener('click', (e) => {
      if (
        document.getElementById('bgm') &&
        document.getElementById('bgm').paused &&
        !this.$store.state.played
      ) {
        this.$store.commit('setPlayed', true)
        setTimeout(() => {
          document.getElementById('bgm').volume = 0.1
          document.getElementById('bgm').play()
        }, 0)

        setTimeout(() => {
          if (document.getElementById('sound')) {
            document.getElementById('sound').volume = 0.2
            document.getElementById('sound').play()
          }
        }, 1000)
      }
    })

    document.getElementById('o2vr_container_fenghuang').addEventListener('touchstart', (e) => {
      if (
        document.getElementById('bgm') &&
        document.getElementById('bgm').paused &&
        !this.$store.state.played
      ) {
        this.$store.commit('setPlayed', true)
        setTimeout(() => {
          document.getElementById('bgm').volume = 0.1
          document.getElementById('bgm').play()
        }, 0)

        setTimeout(() => {
          if (document.getElementById('sound')) {
            document.getElementById('sound').volume = 0.2
            document.getElementById('sound').play()
          }
        }, 1000)
      }
    })

    if (this.$store.state.token.includes('guest')) {
      let info = {
        id: JSON.parse(localStorage.getItem('dianxin_token')).id,
        name: JSON.parse(localStorage.getItem('dianxin_token')).name,
        auth: 0,
        type: 'guest',
        black: 0
      }

      this.$store.commit('chat/setRole', info)
      console.log(this.$store.state.chat.role)

      const config = [
        this.$store.state.websocket,
        {
          name: this.$store.state.chat.role.name,
          id: this.$store.state.chat.role.id,
          auth: this.$store.state.chat.role.auth ? this.$store.state.chat.role.auth : 0,
          info: {
            modelIdx: this.$store.state.chat.role.black ? this.$store.state.chat.role.black : 0
          }
        },
        this.$store.state.roomId
      ]

      this.$store.commit('chat/changeLoading', true)

      await this.$lyh.init(config, this)

      return
    }
  },

  created() {
    this.Bus.$on('openSetSit', (e) => {
      this.data = e
    })

    this.Bus.$on('setuserId', ({ id, auth }) => {
      this.userId = id
      this.auth = auth
    })

    this.Bus.$on('closeTUILIU', () => {
      this.Bus.$emit('screenStream', '')
      this.$store.commit('chat/setStreamShow', false)
      this.beforeunloadHandler()
    })

    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
  },
  data() {
    return {
      text: '',
      data: null,

      stream: '',

      userId: '',
      auth: 0,
      options: [
        { label: '普通用户', value: 0 },
        { label: 'Vip', value: 1 },
        { label: '管理员', value: 2 }
      ]
    }
  },
  methods: {
    async confirm() {
      if (!this.text) {
        this.$message.info('不可为空')
        return
      }

      let instance = this.$loading({
        fullscreen: true,
        attach: 'body',
        preventScrollThrough: false,
        showOverlay: true,
        text: '请求中..'
      })

      // 30秒自动关闭
      const timer = setTimeout(() => {
        clearTimeout(timer)
        instance.hide()
        instance = null
        console.log('超时,自动关闭窗口')
      }, 30000)

      let res = await add_sit({
        name: this.$store.state.initConfig.sceneList[
          this.$store.state.sceneCurrentIndex
        ].sceneId.includes('phoenix')
          ? 'phoenix' + this.text
          : this.text,
        x: this.data.x,
        y: this.data.y,
        z: this.data.z,
        angle: this.data.angle
      })

      if (instance) {
        instance.hide()
        clearTimeout(timer)
      }

      document.o2play.add_lable_to_sit(this.text, this.data.x, this.data.y + 1000, this.data.z)

      this.$message.success('座位编辑成功')

      this.$store.commit('chat/changeSitShow', false)

      console.log(res)
    },
    close() {
      this.text = ''
      this.data = null
      this.$store.commit('chat/changeSitShow', false)
    },
    sendStream() {
      if (!this.stream) {
        this.$message.info('不可为空')
        return
      }

      // https://o2public.oss-cn-beijing.aliyuncs.com/%E5%85%83%E4%B8%80%E7%95%85%E6%83%B3LOGO%E8%A7%86%E9%A2%91.mp4
      console.log(this.stream)
      // this.Bus.$emit('screenStream', this.stream)
      this.$store.commit(
        'chat/send',
        `${this.$store.state.chat.connectInfo.CMD_SCENE_STATE},${JSON.stringify({
          send: this.$store.state.chat.role.id,
          roomid: this.$store.state.roomId,
          url: this.stream
        })}`
      )
    },
    // 结束流
    endStream() {
      this.stream = ''
      this.Bus.$emit('screenStream', '')
      this.$store.commit('chat/setStreamShow', false)

      this.$store.commit(
        'chat/send',
        `${this.$store.state.chat.connectInfo.CMD_SCENE_STATE},${JSON.stringify({
          send: this.$store.state.chat.role.id,
          roomid: this.$store.state.roomId,
          url: ''
        })}`
      )
    },
    // 关闭流界面
    closeStream() {
      this.$store.commit('chat/setStreamShow', false)
    },
    beforeunloadHandler() {
      if (this.$store.state.chat.sendStreamId === this.$store.state.chat.role.id) {
        console.log('离开')
        this.$store.commit(
          'chat/send',
          `${this.$store.state.chat.connectInfo.CMD_SCENE_STATE},${JSON.stringify({
            send: this.$store.state.chat.role.id,
            roomid: this.$store.state.roomId,
            url: ''
          })}`
        )
      }
    },
    async editAuth() {
      if (!this.userId) {
        this.$message.info('不可为空')
        return
      }

      let instance = this.$loading({
        fullscreen: true,
        attach: 'body',
        preventScrollThrough: false,
        showOverlay: true,
        text: '请求中..'
      })

      // 30秒自动关闭
      const timer = setTimeout(() => {
        clearTimeout(timer)
        instance.hide()
        instance = null
        console.log('超时,自动关闭窗口')
      }, 30000)

      const res = await edit({
        auth: this.auth,
        id: parseInt(this.userId)
      })

      if (instance) {
        instance.hide()
        clearTimeout(timer)
      }

      if (res.code == 0) {
        this.$store.commit('chat/editAuth', false)
        this.$store.commit('chat/changeAuth', this.auth)
        this.$message.success('修改成功')
      }
    },
    closeEditAuthShow() {
      this.$store.commit('chat/editAuth', false)
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
  }
}
</script>

<style lang="scss">
// @media screen and (orientation: portrait) {
//   #app,
//   html {
//     width: 100%;
//     height: 100%;
//   }

//   #app {
//     position: absolute;
//     width: 100vh;
//     height: 100vw;
//     top: 0;
//     left: 100vw;
//     transform: rotate(90deg);
//     transform-origin: 0% 0%;
//     overflow: hidden;
//   }
// }
</style>
