<template>
  <div>
    <XyzTransition xyz="fade"
      ><div
        class="back"
        v-show="
          $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow
        "
      >
        <div
          class="reback"
          :style="{
            background: `url(${require('@assets/img/exit.png')}) no-repeat`
          }"
          @click="close"
        ></div>
        <span class="name">{{ $store.state.playVideoName }}</span>
      </div></XyzTransition
    >

    <XyzTransition xyz="fade">
      <div
        class="progress"
        v-show="
          $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow
        "
      >
        <div
          class="playandpause"
          :style="{
            background: `url(${
              $store.state.videoplayState === 1
                ? require('@assets/img/pause.png')
                : require('@assets/img/play.png')
            }) no-repeat`
          }"
          @click="playandpause"
        ></div>

        <div class="probox">
          <el-slider v-model="value1" :show-tooltip="false" @change="change"></el-slider>
        </div>

        <div class="duration">{{ $store.state.duration }}</div>
      </div></XyzTransition
    >

    <XyzTransition
      xyz="fade"
      v-show="
        $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow
      "
    >
      <div
        class="introduce"
        :style="{
          background: `url(${require('@assets/img/简介@2x.png')}) no-repeat`
        }"
        @click="$store.commit('setVideoIntroShow', true)"
      >
        <span>简介</span>
      </div>
    </XyzTransition>

    <div class="json360" v-show="$store.state.videoplayState === 1" id="json360"></div>

    <XyzTransition :xyz="$store.state.chat.yaoganWatch === 2 ? 'fade right' : 'fade down'">
      <div
        class="shadow"
        v-show="$store.state.videoIntroShow"
        @click="$store.commit('setVideoIntroShow', false)"
      >
        <div class="cebian" @click.stop="">
          <span class="title">视频简介</span>

          <div class="content">
            <div class="contentitem">
              {{ $store.state.intro[$store.state.playVideoName] }}
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>

    <div
      class="shadowtop"
      v-show="
        $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow
      "
    ></div>
    <div
      class="shadowbottom"
      v-show="
        $store.state.playVideoName && $store.state.videoSlowShow && !$store.state.videoIntroShow
      "
    ></div>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import json360 from '@assets/js/rotate_media_play.json'

export default {
  name: 'WebVideoPlay',

  data() {
    return {
      value1: 0
    }
  },

  created() {
    this.Bus.$on('onPanoUpdate', (e) => {
      this.value1 = e * 100
    })
  },

  methods: {
    change() {
      document.sInteraction.panoVideo.videoElement.currentTime =
        document.sInteraction.panoVideo.videoElement.duration * (this.value1 / 100)
    },
    playandpause() {
      if (this.$store.state.videoplayState === 1) {
        document.sInteraction.panoVideo.videoElement.pause()
        this.$store.commit('setPlayVideoState', 2)
      } else {
        document.sInteraction.panoVideo.videoElement.play()
        this.$store.commit('setPlayVideoState', 1)
      }
    },
    close() {
      if (document.getElementById('bgm') && document.getElementById('bgm').paused) {
        document.getElementById('bgm').volume = 0.1
        document.getElementById('bgm').play()

        if (document.getElementById('sound')) {
          document.getElementById('sound').volume = 0.2
          document.getElementById('sound').play()
        }
      }
      this.$store.commit('setPlayVideoState', 0)
      this.$store.commit('setPlayVideoName', '')
      this.$store.commit('chat/changeWebui', true)
      this.Bus.$emit('panoVideo', false)
    },
    checkFullScreen() {
      var isFull =
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement ||
        document.fullscreenElement
      if (isFull == null || isFull == undefined) {
        isFull = false
      }
      return isFull
    },
    // 全屏
    toggleFullScreen() {
      // video 是 video-player 里面的 video 标签，这个用于 ios 端全屏
      var video = document.sInteraction.panoVideo.videoElement
      // IOS（特例）
      if (video?.webkitEnterFullscreen) {
        video?.webkitEnterFullscreen()
      }
    }
  },
  mounted() {
    const json = lottie.loadAnimation({
      container: document.getElementById('json360'), //当前需要渲染的DOM
      renderer: 'svg', //渲染方式，默认为svg,还可以渲染为html和canvas
      loop: true, //循环播放
      autoplay: true, //自动播放
      animationData: json360 //本地动画
    })

    json.play()
  }
}
</script>

<style lang="scss" scoped>
.back {
  position: fixed;
  left: 30px;
  top: 24px;
  z-index: 100;
  display: flex;
  align-items: center;

  .reback {
    width: 24px;
    height: 24px;
    background-size: $bs !important;
  }

  .name {
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
  }
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 10px;
  height: 30px;
  z-index: 100;
  width: 100%;

  .playandpause {
    width: 20px;
    height: 20px;
    background-size: $bs !important;
    margin: 0 15px 2px 0;
  }

  .probox {
    width: calc(100% - 150px);
    // height: 3px;
    // background: rgba($color: #ffffff, $alpha: 0.3);

    &::v-deep .el-slider__runway {
      height: 3px;
    }

    &::v-deep .el-slider__bar {
      height: 3px;
      background: linear-gradient(to right, #236fc6, #39c6b0);
      border-radius: 0;
    }

    &::v-deep .el-slider__button-wrapper {
      top: -16px;
    }

    &::v-deep .el-slider__button {
      background: url('../../assets/img/move.png') no-repeat;
      background-size: 100% 100%;
      border: 0;
      width: 20px;
      height: 20px;
      // box-shadow: 0 0 10px rgba(95, 122, 163, 0.6);
    }

    .pro {
      position: relative;
      height: 100%;
      background: linear-gradient(to right, #236fc6, #39c6b0);

      .header {
        position: absolute;
        right: -10px;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        background-size: $bs !important;
      }
    }
  }

  .duration {
    color: #fff;
    margin: 0 0 2px 10px;
  }
}

.introduce {
  position: fixed;
  right: 30px;
  top: calc(50% - 17px);
  z-index: 99;
  width: 34px;
  height: 34px;
  background-size: $bs !important;

  span {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, calc(100% + 1px));
    color: #fff;
    letter-spacing: 1px;
    white-space: nowrap;
    font-size: 12px;
    text-shadow: 0px 1px 2px rgba($color: #000000, $alpha: 0.64);
  }
}

.json360 {
  position: fixed;
  right: 17px;
  top: 10px;
  z-index: 99;
  width: 60px;
  height: 60px;
}

.shadowtop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 55px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.shadowbottom {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  width: 100vw;
  height: 55px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.shadow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 99;

  .cebian {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(830px * 0.3);
    height: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background: linear-gradient(to bottom, #0e253f, #0e3f3f 95%);
    border: 1px solid #39c6b0;
    border-right: 0;

    .title {
      color: #fff;
      margin: 36px 0 10px 30px;
      font-size: 18px;
    }

    .content {
      color: #b9b9b9;
      font-size: 12px;
      margin: 10px 0 0 30px;
      padding-right: 30px;
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      line-height: 18px;
      word-break: break-all;

      &::-webkit-scrollbar {
        width: 4px;
        height: 10px;
      }
      /* 设置滚动条的轨道 */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* 设置滚动条里面的小方块 */
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #ffffff, $alpha: 0.3);
        border-radius: 10px;
      }

      .contentitem {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .shadow {
    justify-content: center;
    align-items: flex-end;

    .cebian {
      width: 100%;
      height: 314px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 24px;
    }
  }
}
</style>
