<template>
  <div>
    <XyzTransition xyz="fade">
      <div
        class="action"
        v-if="$store.state.actionShow"
        :class="{
          move: $store.state.shotShow && $store.state.chat.yaoganWatch === 2,
          moves: $store.state.shotShow && $store.state.chat.yaoganWatch === 4
        }"
      >
        <div
          class="box"
          :class="{
            boxs: i === $store.state.actionCurrent
          }"
          v-for="(item, i) in list"
          :key="i"
          @click="clicks(item, i)"
        >
          <img :src="item.src" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </XyzTransition>

    <img class="shadow" src="" alt="" v-if="$store.state.actionShow" @click="close" />
  </div>
</template>

<script>
export default {
  name: 'WebAction',
  data() {
    return {
      list: [
        {
          name: '点头',
          src: require('@assets/img/action/点头@2x.png'),
          action: 'agree'
        },
        {
          name: '鼓掌',
          src: require('@assets/img/action/鼓掌@2x.png'),
          action: 'clap'
        },
        {
          name: '反对',
          src: require('@assets/img/action/反对@2x.png'),
          action: 'disagree'
        },
        {
          name: '你好',
          src: require('@assets/img/action/你好@2x.png'),
          action: 'greeting'
        },
        {
          name: '跳舞1',
          src: require('@assets/img/action/跳舞1@2x.png'),
          action: 'dance1'
        },
        {
          name: '跳舞2',
          src: require('@assets/img/action/跳舞二@2x.png'),
          action: 'dance2'
        }
      ]
    }
  },
  methods: {
    clicks(item, i) {
      let bgm = document.getElementById('touchbgm')
      if (bgm) {
        bgm.play()
      }
      this.$store.commit('changeActionCurrent', i)
      document.o2.ws.send(`1056,playClip,${item.action}`)
      document.o2.ws.send(`1056,gif,${i}`)
    },
    close() {
      this.$store.commit('changeActionShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
.action {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  right: 95px;
  top: calc(84px + 15px + 34px / 2);
  z-index: 99;
  padding: 10px;
  width: 178px;
  height: 115px;
  background: linear-gradient(to bottom, #0e253f, #0e3f3f);
  border: 1px solid #39c6b0;
  border-radius: 4px;
  border-bottom: none;
  transition: all 0.2s;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: rgba($color: #fff, $alpha: 0.2);
    margin-bottom: 10px;
    border-radius: 2px;
    font-size: 12px;
    color: #c4c4c4;
    border: 1px solid transparent;

    img {
      width: 20px;
      margin-top: 5px;
    }

    span {
      letter-spacing: 1px;
      transform: scale(0.9);
      margin-top: 2px;
    }

    &:nth-child(4) {
      margin-bottom: 0;
    }
    &:nth-child(5) {
      margin-bottom: 0;
    }
    &:nth-child(6) {
      margin-bottom: 0;
    }
  }

  .boxs {
    border: 1px solid #39c6b0;
  }

  .touch {
    border: 1px solid;
  }
}

.shadow {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: visible;
}

.move {
  right: 125px;
  top: initial;
  bottom: calc(42px + 10px - 30px);
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .action {
    right: calc(84px + 26px + 10px - 34px + 5px);
    top: initial;
    bottom: calc(42px + 10px + 34px);
  }

  .moves {
    right: 28px;
    top: initial;
    bottom: calc(42px + 10px + 34px + 5px);
  }
}
</style>
