<template>
  <div>
    <div class="loading" v-if="!$store.state.notFirst">
      <!-- <img class="logo-lefttop" src="@assets/img/logo.png" alt="" /> -->
      <img src="@assets/img/logog.gif" class="logo-center" />

      <div class="loading-tiao">
        <img
          class="loading-lion"
          src="@assets/img/lion.png"
          alt=""
          :style="'left:' + (width - $store.state.chat.yaoganWatch) + '%'"
        />
        <div class="loading-msg">{{ msg }}</div>
        <div class="loading-tips">
          <img :src="tips[tips_index].src" alt="" />
          <div class="duan">
            <span>{{ tips[tips_index].tittle }}</span>
            <span>{{ tips[tips_index].content }}</span>
          </div>
        </div>
        <div class="loading-full" :style="'width:' + width + '%'"></div>
      </div>
    </div>

    <div v-else class="shadow">
      <img src="@assets/img/deliver.gif" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: '',
      width: 0,
      tips: [
        {
          tittle: '在数字藏品区',
          content: '广场上有数字藏品互动哦',
          src: require('@assets/img/k1.png')
        },
        {
          tittle: '',
          content: '使用左侧滑轮可控制人物移动',
          src: require('@assets/img/k2.png')
        },
        {
          tittle: '使用右手在右侧屏幕滑动',
          content: '可调节画面视角',
          src: require('@assets/img/k3.png')
        },
        {
          tittle: '岛屿上多处可触发全景视频观看',
          content: '可以点击竹筒、灯笼、冰块观看',
          src: require('@assets/img/k4.png')
        },
        {
          tittle: '一个岛屿不够看？',
          content: '点击传送按钮切换岛屿',
          src: require('@assets/img/k5.png')
        }
      ],
      tips_index: 0,
      counter: 0,
      timer: null
    }
  },
  // mounted() {
  // let that = this
  // function imageSequence(duration) {
  //   let lastTime = 0
  //   function updateFrame(time = 0) {
  //     let delta = time - lastTime
  //     if (delta > duration) {
  //       if (that.counter >= 100) {
  //         that.counter = 0
  //       } else that.counter++
  //       lastTime = time
  //     }
  //     requestAnimationFrame(updateFrame)
  //   }
  //   updateFrame()
  // }
  // imageSequence(100)
  // },
  created() {
    this.$o2Init.landscapeWatch()

    const i = this.$lyh.createRandom(this.tips)
    this.tips_index = i

    this.timer = setInterval(() => {
      if (this.tips_index >= this.tips.length - 1) {
        this.tips_index = 0
      } else {
        this.tips_index++
      }
    }, 2000)

    document.o2.on_loading_msg = (a, b, c) => {
      this.msg = `资源全力加载中 ${b}%`
      this.width = b
      if (b == 100) {
        setTimeout(() => {
          this.$store.commit('chat/changeLoading', false)
          this.$store.commit('chat/changeWebui', true)
          // 预加载GIF
          this.$lyh.preloadImage(require('@assets/img/deliver.gif'))
          if (!this.$store.state.notFirst) {
            // 已不是第一次登录 换一种loading方式
            this.$store.commit('changeNotFirst', true)
          }
          clearInterval(this.timer)
          this.timer = null
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    z-index: 9999;

    .loading-tiao {
      position: fixed;
      bottom: 94px;
      margin: 0 28px;
      width: calc(100vw - 56px);
      height: 4px;
      background: #4c4c4c;

      .loading-msg {
        position: absolute;
        left: 0;
        top: -27px;
        color: #888888;
        font-size: 12px;
        letter-spacing: 0.5px;
      }

      .loading-tips {
        display: flex;
        align-items: center;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        top: -165px;
        height: 40px;

        img {
          width: 30px;
          margin-right: 5px;
        }

        .duan {
          display: flex;
          flex-direction: column;

          span {
            color: #8c8c8c;
            font-size: 12px;
            letter-spacing: 0.5px;
            white-space: nowrap;
          }
        }
      }

      .loading-full {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(to left, #236fc6, #39c6b0);
        transition: all 1s linear;
      }

      .loading-lion {
        position: absolute;
        top: calc(-36px);
        width: calc(68px * 0.5);
        height: calc(90px * 0.5);
        transition: all 1s linear;
      }
    }

    .logo-lefttop {
      position: absolute;
      top: 17px;
      left: 11px;
      width: 44px;
    }

    .logo-center {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(640px * 0.25);
      height: calc(480px * 0.25);
    }
  }
}

// PC
@media screen and (min-width: 1400px) {
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    z-index: 9999;

    .loading-tiao {
      position: fixed;
      bottom: 38px;
      margin: 0 40px;
      width: calc(100vw - 80px);
      height: 4px;
      background: #4c4c4c;

      .loading-msg {
        position: absolute;
        left: 0;
        top: -27px;
        color: #888888;
        font-size: 12px;
        letter-spacing: 0.5px;
      }

      .loading-tips {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: -72px;
        height: 40px;

        img {
          width: 30px;
          margin-right: 5px;
        }

        .duan {
          display: flex;
          flex-direction: column;

          span {
            color: #8c8c8c;
            font-size: 12px;
            letter-spacing: 0.5px;
            white-space: nowrap;
          }
        }
      }

      .loading-full {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(to left, #236fc6, #39c6b0);
        transition: all 1s linear;
      }

      .loading-lion {
        position: absolute;
        top: calc(-36px);
        width: calc(68px * 0.5);
        height: calc(90px * 0.5);
        transition: all 1s linear;
      }
    }

    .logo-lefttop {
      position: absolute;
      top: 17px;
      left: 11px;
      width: 44px;
    }

    .logo-center {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(640px * 0.25);
      height: calc(480px * 0.25);
    }
  }
}

// // 移动端 横屏
@media screen and (max-width: 999px) and (max-height: 500px) {
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    z-index: 9999;

    .loading-tiao {
      position: fixed;
      bottom: 38px;
      margin: 0 40px;
      width: calc(100vw - 80px);
      height: 4px;
      background: #4c4c4c;

      .loading-msg {
        position: absolute;
        left: 0;
        top: -27px;
        color: #888888;
        font-size: 12px;
        letter-spacing: 0.5px;
      }

      .loading-tips {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: -72px;
        height: 40px;

        img {
          width: 30px;
          margin-right: 5px;
        }

        .duan {
          display: flex;
          flex-direction: column;

          span {
            color: #8c8c8c;
            font-size: 12px;
            letter-spacing: 0.5px;
            white-space: nowrap;
          }
        }
      }

      .loading-full {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(to left, #236fc6, #39c6b0);
        transition: all 1s linear;
      }

      .loading-lion {
        position: absolute;
        top: calc(-36px);
        width: calc(68px * 0.5);
        height: calc(90px * 0.5);
        transition: all 1s linear;
      }
    }

    .logo-lefttop {
      position: absolute;
      top: 17px;
      left: 11px;
      width: 44px;
    }

    .logo-center {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(640px * 0.25);
      height: calc(480px * 0.25);
    }
  }
}

.shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #000;

  img {
    width: calc(270px * 0.5);
    transform: rotate(-30deg);
  }
}
</style>
