<template>
  <div>
    <yaogan v-if="$store.state.chat.webuiSwitch && $lyh.isMobile()" />
    <pageBtns v-if="$store.state.chat.webuiSwitch" />
    <guestTips />

    <set />
    <maps />
    <personalCenter />
    <shot />
    <action />
    <playvideo />
    <xieyi />
  </div>
</template>

<script>
import yaogan from './pageFunc/circle.vue'
import pageBtns from './pageFunc/pageBtns.vue'
import guestTips from './pageFunc/guestTips.vue'
import set from './pageFunc/set.vue'
import maps from './pageFunc/map.vue'
import personalCenter from './pageFunc/personalCenter.vue'
import shot from './pageFunc/shot.vue'
import action from './pageFunc/action.vue'
import playvideo from './pageFunc/videoPlay.vue'
import xieyi from './pageFunc/xieyi.vue'
export default {
  data() {
    return {}
  },
  components: {
    yaogan,
    pageBtns,
    guestTips,
    set,
    maps,
    personalCenter,
    shot,
    action,
    playvideo,
    xieyi
  },
  methods: {},
  created() {}
}
</script>
