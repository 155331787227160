var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('XyzTransition',{attrs:{"xyz":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.centerShow),expression:"$store.state.centerShow"}],staticClass:"center",style:({
        background: ("url('" + (_vm.$store.state.chat.yaoganWatch === 4
            ? require('@assets/img/center2.png')
            : require('@assets/img/center.png')) + "') no-repeat"),
        'background-size': _vm.$store.state.chat.yaoganWatch === 4 ? '100% auto' : '100% 100%'
      })},[_c('div',{staticClass:"back",style:({
          background: ("url('" + (require('@assets/img/back.png')) + "') no-repeat")
        }),attrs:{"id":"back"},on:{"click":_vm.back}},[_c('span',[_vm._v("形象设置")])]),_c('div',{staticClass:"intro",attrs:{"id":"intro"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.avatarList[_vm.$store.state.currentAvatarSelect - 1].name))]),_c('img',{staticClass:"logo",attrs:{"src":require("@assets/img/logo.png"),"alt":""}})]),_c('div',{staticClass:"green"}),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.avatarList[_vm.$store.state.currentAvatarSelect - 1].content))]),_c('div',{staticClass:"decorate"},[_c('div',{staticClass:"xie"}),_c('div',{staticClass:"xie"}),_c('div',{staticClass:"xie"}),_c('div',{staticClass:"box"})])]),_c('div',{staticClass:"man",attrs:{"id":"man"},on:{"touchstart":_vm.onTouchStart,"touchmove":_vm.onTouchMove,"touchend":_vm.onTouchEnd}},[_c('img',{attrs:{"src":_vm.rebackImg(),"alt":""}})]),_c('div',{staticClass:"selectAvatar"},[_c('div',{staticClass:"head"},[_vm._v("选择形象")]),_c('div',{staticClass:"avatarBox"},_vm._l((_vm.avatarList),function(item,i){return _c('div',{key:i,staticClass:"avatar",style:({
              background:
                i + 1 === _vm.$store.state.currentAvatarSelect
                  ? 'linear-gradient(to right, #39c6b0, #236fc6)'
                  : ''
            }),on:{"click":function($event){return _vm.changeRole(i)}}},[_c('img',{style:({
                width: '50px',
                height: '50px'
              }),attrs:{"src":item.src,"alt":""}})])}),0),_c('div',{staticClass:"setName"},[_c('div',{staticClass:"head2"},[_vm._v("设置昵称")]),_c('div',{staticClass:"name"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"nameinput",attrs:{"id":"inputBox","type":"text","maxlength":"8"},domProps:{"value":(_vm.name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.name=$event.target.value},_vm.inputChange]}}),_c('div',{staticClass:"touzi",on:{"click":_vm.randomName,"touchstart":function($event){_vm.hover.touzi = true},"touchend":function($event){_vm.hover.touzi = false}}},[_c('img',{attrs:{"src":_vm.hover.touzi
                    ? require('@assets/img/touzi-hover.png')
                    : require('@assets/img/touzi.png'),"alt":""}})])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }