<template>
  <div>
    <XyzTransition :xyz="$store.state.chat.yaoganWatch === 2 ? 'fade right' : 'fade down'">
      <div
        class="shadow"
        v-show="$store.state.setShow"
        @click="$store.commit('changeSetShow', false)"
      >
        <div
          class="cebian"
          @click.stop=""
          :class="{
            bshu: $store.state.chat.yaoganWatch === 4,
            bhen: $store.state.chat.yaoganWatch === 2
          }"
        >
          <span class="title">设置</span>

          <div class="hang">
            <div class="ht" v-for="(item, i) in list" :key="i" @click="clickmethod(i)">
              <span>{{ item.name }}</span>
              <div
                class="arrow"
                :style="{
                  background: `url('${require('@assets/img/arrow.png')}') no-repeat`
                }"
              ></div>
            </div>
          </div>

          <div class="logout" @click="logout">
            退出图壤
          </div>
        </div>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '新手教程'
        },
        {
          name: '关于我们'
        }
      ]
    }
  },
  methods: {
    logout() {
      this.$store.commit('changeOpenModalShowInfo', {
        title: '确认退出图壤？',
        ok: '确认',
        cancel: '取消',
        function: 'logout'
      })
      this.$store.commit('changeOpenModalShow', true)
    },
    clickmethod(i) {
      this.$store.commit('setxieyi', {
        show: true,
        data: i + 2
      })
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.shadow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 99;

  .cebian {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(830px * 0.3);
    height: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background: linear-gradient(to bottom, #0e253f, #0e3f3f 95%);

    .title {
      color: #fff;
      margin: 36px 0 10px 30px;
      font-size: 18px;
    }

    .hang {
      display: flex;
      flex-direction: column;
      width: calc(100% - 60px);
      margin: 0 30px;

      .ht {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        padding: 12px 0;
        border-bottom: 1px solid rgba($color: #adadad, $alpha: 0.4);

        span {
          color: #e6e6e6;
          font-size: 14px;
        }

        .arrow {
          width: calc(14px);
          height: calc(14px);
          background-size: $bs !important;
        }
      }
    }

    .logout {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 36px;
      width: 114px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: rgba($color: #ffffff, $alpha: 0.1);
      border-radius: 8px;
      color: #e6e6e6;
      font-size: 12px;
    }
  }

  .bshu {
    border-top: 1.3px solid #39c6b0;
  }

  .bhen {
    border-left: 1.1px solid #39c6b0;
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .shadow {
    justify-content: center;
    align-items: flex-end;

    .cebian {
      width: 100%;
      height: 314px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 24px;
    }
  }
}
</style>
